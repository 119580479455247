export default [
  {
    name: "john_TR_Bonanza_1",
    id: "1698778630605980",
  },
  {
    name: "john_360_2",
    id: "312982588280794",
  },
  {
    name: "franck_435",
    id: "891651145692924",
  },
  {
    name: "peter_1",
    id: "392557469773892",
  },
  {
    name: "shub_in",
    id: "2402119006631691",
  },
  {
    name: "leon_lanen",
    id: "2327212847474579",
  },
  {
    name: "the_guvna",
    id: "956938165916468",
  },
  {
    name: "franck_435_2",
    id: "464140302787532",
  },
  {
    name: "franck_435_1",
    id: "471352738693470",
  },
  {
    name: "360_2",
    id: "1615543522656298",
  },
  {
    name: "franck_435_2_2",
    id: "1199799998012096",
  },
  {
    name: "frank_435_1_2",
    id: "1032457178883666",
  },
  {
    name: "john_360_1_2",
    id: "417169201382731",
  },
  {
    name: "frank_435_1_3",
    id: "1037075551406039",
  },
]
